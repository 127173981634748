<template>
    <div>
        <Header :instanceInfo="instanceInfo" />
        <div class="container">
            <div class="col-md-12">
                <h2 class="pink">
                    Disclaimer
                </h2>
            </div>
            <div class="col-md-12">
                The minimum age for participation on this Website is 18 years. The Website is optimized for desktop, mobile,
                and tablets. The Website offers a digital fantasychat for men and women looking for a fun, flirty, and
                exciting adult chat in a secure digital environment. Based on your settings, you will receive chat and role
                play suggestions by animators with whom real-life encounters are not possible. Before using the Website,
                please read the <router-link to="/terms">Terms of Service</router-link> and <router-link to="/privacy">Privacy policy</router-link>.
                If you want to learn more about pricing, please <router-link to="/pricing">click here</router-link>.
                <br>
                <br>
            </div>
        </div>
        <Footer :instance-info="instanceInfo" :company-name="companyName" v-bind="$attrs" />
    </div>
</template>

<script>
import Header from '@/app/components/Header';
import Footer from '@/app/components/Footer';

export default {
    name: 'Disclaimer',
    components: { Header, Footer },
    props: {
        instanceInfo: {
            type: Object,
            required: false,
        },
        companyName: {
          type: String,
          required: false,
        },
    },
};
</script>
